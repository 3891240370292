
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 80px;
}

.titles {
  display: flex;
  margin-bottom: 30px;
}

.title {
  width: 50%;
  margin-bottom: 0;
}

.subTitle {
  width: 25%;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.featuresTable {
  background: #fff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.featureRow {
  display: flex;
  align-items: center;
  padding: 16px 0;
  background: #fff;

  &:nth-child(odd) {
    background: rgba(255,209,189,0.3);
  }
}

.feature {
  width: 50%;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 16px;
}

.column {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $color-pink;
}

@media only screen and (min-width: 992px) {
  .titles {
    align-items: center;
  }

  .title {
    width: 40%;
  }

  .subTitle {
    width: 15%;
  }

  .featureRow {
    padding: 30px 0;
  }

  .feature {
    width: 40%;
    padding: 0 40px;
    font-size: 18px;
  }

  .column {
    width: 15%;
  }
}
