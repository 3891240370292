
        @import 'styles/variables';
        @import 'styles/media';
      
.headerSpacer {
  display: block;
  height: 80px;
  max-height: 80px;
  margin-bottom: 80px;
}

.siteHeader {
  display: none;
  padding: 23px 0 18px;
  max-height: 80px;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.alternative {
  background-color: transparent;
  position: absolute;
}

.wrap {
  display: flex;
  justify-content: space-between;
}

.logoWrap {
  flex-basis: 20%;
}

.navWrap {
  text-align: right;
}

@media only screen and (min-width: 992px) {
  .siteHeader {
    display: block;
  }
}