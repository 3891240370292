
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  background: linear-gradient(0deg, rgba(229, 229, 229, 0.5) 50%, white 50%);
  margin-bottom: 0px;
  padding-bottom: 0px;
  overflow: hidden;

  .header {
    position: relative;
    margin-bottom: 40px;
  }

  .title {
    font-weight: 500;
  }
}



@media only screen and (min-width: 768px) {
  .section {
    background: linear-gradient(0deg, rgba(229, 229, 229, 0.5) 65%, white 35%);
  }
}

@media only screen and (min-width: 992px) {
  .title {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    margin-bottom: 10px;
  }

  .description {
    font-size: $body-small-font-size;
    line-height: $body-small-line-height;
  }
}
