
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 40px;
}

.wrap {
  position: relative;
  padding: 40px 20px 30px 20px;
  background-color: $color-blush-50;
  border-radius: 10px;
}

.header {
  margin-bottom: 60px;
}

.title {
  font-size: 18px;
  line-height: 1.5;
}

.bigText {
  font-size: 24px;
  line-height: 36px;
}

.button {
  min-width: 200px;
  max-width: 220px;
}

.decoration {
  position: absolute;
  width: 75px;
  bottom: 30px;
  right: 20px;
  transform: rotate(20deg) scaleX(-1);
}

@media only screen and (min-width: 768px) {
  .wrap {
    padding: 70px 80px 60px;
  }

  .header {
    max-width: 315px;
    margin-bottom: 30px;
  }

  .decoration {
    transform: rotate(5deg);
    width: 150px;
    top: 30px;
    right: 40px;
    bottom: initial;
  }
}

@media only screen and (min-width: 992px) {
  .section {
    margin-bottom: 50px;
  }

  .header {
    max-width: 515px;
  }

  .title {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  }

  .decoration {
    top: 40px;
    right: 70px;
  }
}

@media only screen and (min-width: 1200px) {
  .wrap {
    background-color: $color-blush;
  }

  .decoration {
    top: 30px;
    right: 135px;
    width: 180px;
  }
}
