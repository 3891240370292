
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  position: relative;
}

.myAccount {
  cursor: pointer;
  color: $color-pink;

  &:hover {
    text-decoration: underline;;
  }

  &.alternative {
    color: white;
  }
}

.dropdown {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid rgba(60, 60, 60, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  min-width: 254px;
  top: 40px;
  right: -100px;
  text-align: left;
  padding-bottom: 50px;
}

.item {
  cursor: pointer;
  padding: 40px 40px 44px 40px;
  border-bottom: 1px solid rgba(60, 60, 60, 0.05);

  &:hover {
    color: $color-pink;
  }

  &:last-of-type {
    padding: 14px 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .dropdown {
    right: -30px;
  }
}

@media only screen and (min-width: 1400px) {
  .dropdown {
    right: -100px;
  }
}