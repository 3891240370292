
        @import 'styles/variables';
        @import 'styles/media';
      
.hamburger {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 5px;
    margin-right: 20px
  }
  
  .part {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #3C3C3C;;
    transition: all 0.2s ease-out;
  }

  .altPart {
    background-color: white;
  }
  
  .open {
    z-index: 999;

    span {
      background-color: $color-body;
      
      &:nth-child(1) {
        position: relative;
        top: 5px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        position: relative;
        top: -7px;
        transform: rotate(-45deg);
      }
    }
  }