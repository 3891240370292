
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
    margin-top: -80px;
    margin-bottom: 80px;
    padding: 40px 0;
    background-color: $color-blush-50;
  }

.linkButton {
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
  line-height: 18px;
  padding: 12px 22px;
  text-align: center;
  min-width: 130px;
  border: none;
  cursor: pointer;
  color: white;
  background-color: $color-pink;
  font-family: $font-body;
  text-decoration: none;
  &:hover {
	  background-color: rgba(255, 101, 139, 0.8);
  }
}

.wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  a {
    margin-top: 26px;
  }
}

.imageWrap {
  display: none;
}

.imageFrame {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;
}

.decoration {
  position: absolute;
  width: 75px;
  bottom: -90px;
  right: 0;
  transform: rotate(10deg) scaleX(-1);
}

@media only screen and (min-width: 768px) {
  .wrap {
    flex-wrap: nowrap;
  }

  .contentWrap {
    flex-basis: 70%;
  }

  .title {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
  }
}

@media only screen and (min-width: 992px) {
  .section {
    padding: 70px 0;
  }

  .contentWrap {
    padding-right: 50px;
  }

  .contentWrap,
  .imageWrap {
    flex-basis: 50%;
  }

  .imageWrap {
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .contentWrap {
    padding: 40px 90px 40px 0;
  }

  .decoration {
    transform: initial;
    right: initial;
    left: 0;
    bottom: -112px;
  }
}
