
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 80px;
}

.message {
  height: 160px;
}

.submit {
  width: 100%;
  margin-top: 40px;
}

.error {
  color: $color-pink;
}

@include media-tablet {
  .wrap {
    display: flex;
    justify-content: space-between;
  }

  .content {
    flex-basis: 55%;
  }

  .form {
    flex-basis: 40%;
  }

  .submit {
    width: initial;
    float: right;
  }
}

@include media-desktop {
  .content,
  .form {
    flex-basis: 43%;
  }
}