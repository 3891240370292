
        @import 'styles/variables';
        @import 'styles/media';
      
.testcard {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100vw;
  box-shadow: 0px -15px 30px -15px rgba(68, 33, 33, 0.1);
  padding: 15px 0;
  z-index: 99999
}

@media only screen and (max-width: 992px) {
  .testcard {
    text-align: center;
  }
}