@mixin media-tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin media-desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}