
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 50px;
}

.step {
  display: flex;
  margin-bottom: 40px;
}

.number {
  color: white;
  background-color: $color-pink;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 40px;
  text-align: center;
}

.content {
  font-size: 16px;
  line-height: 26px;
}

@media only screen and (min-width: 768px) {
  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;;
  }

  .step {
    flex-basis: 65%;
  }
}

@media only screen and (min-width: 992px) {
  .wrap {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .step {
    flex-basis: 30%;
    max-width: 30%;
  }

  .title {
    font-size: $h2-font-size-mobile;
    line-height: $h2-line-height-mobile;
  }
}

@media only screen and (min-width: 1200px) {
  .section {
    margin-bottom: 50px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }
}
