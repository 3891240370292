
        @import 'styles/variables';
        @import 'styles/media';
      
.list {
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: stretch;
  margin-top: 3px;

  li {
    display: inline-block;
    padding-left: 40px;   
  }
}

.link {
  font-size: 16px;
  color: $color-body;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.dropdownLink {
  font-size: 16px;
  color: $color-pink;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.altLink {
  color: white;
}

.dropdown {
  margin-top: -1.5px;;
}

.dropdownMenu {
  position: absolute;
  margin-left: 15px;
  width: auto;
  background-color:transparent;
  flex-direction: column;
  text-align: start;
  display: none;
  padding-top: 28.5px;
}


.dropdownMenuLower {
  position: absolute;
  background-color:transparent;
  margin-left: 15px;
  width: auto;
  flex-direction: column;
  text-align: start;
  display: none;
  padding-top: 13.5px;
}

.dropdownMenu li {
  padding: 10px 25px 10px 25px;
  width: 100%;
  font-weight: 500;
}

.dropdownMenuLower li {
  padding: 10px 25px 10px 25px;
  width: 100%;
  font-weight: 500;
}


.dropdownMenu li:hover {
  background-color: rgb(239, 239, 239);
}

.dropdownMenu li:hover {
  text-decoration: underline!important;
}

.dropdownMenuLower li:hover {
  text-decoration: underline!important;
}

.dropdownMenuLower li:hover {
  background-color: rgb(239, 239, 239);
}

.dropdownMenu li:hover > .dropdownLink{
  text-decoration: underline;
}

.dropdownMenuLower li:hover > .dropdownLink{
  text-decoration: underline;
}

.dropdownLink {
  text-decoration: none;
}

.dropdownLink:hover {
  text-decoration: underline;
  cursor: pointer
}

.dropdown:hover .dropdownMenu {
  display: block;
}

.dropdown:hover .dropdownMenuLower {
  display: block;
}

.dropdownIcon {
  position: relative;
  height: 6px;
  content: url('/img/arrow-down.svg');
  margin-left: 6px;
}

.dropdownIconWhite {
  position: relative;
  height: 6px;
  content: url('/img/arrow-down-white.svg');
  margin-left: 6px;
}

.roundedWrapper {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  overflow: hidden;
}

.roundedWrapperLower {
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
}