
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  position: relative;
  margin: -9rem auto 3.35rem;
  max-width: 1920px;
  text-align: center;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .section {
    top: -1rem;
  }
}


.imgWrap {
  position: relative;
  z-index: 10;
  max-width: 100vw;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      10.14deg,
      rgba(255, 101, 139, 0) 41.61%,
      rgba(255, 101, 139, 0.5) 92.41%
    );
    z-index: 11;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      83.22deg,
      rgba(255, 101, 139, 0.21) 0.59%,
      rgba(255, 135, 0, 0.49) 99.78%
    );
    z-index: 12;
  }
}

.mobileImg {
  width: 170%;
  margin-left: -20%;
  height: 48rem;
  overflow: hidden;
  

  @media only screen and (min-width: 360px) {
    height: 46rem;
    width: 120%;
    margin-left: -10%;
  }
  
  @media only screen and (min-width: 375px) {
    height: 44rem;
    width: 110%;
    margin-left: -5%;
  }

  @media only screen and (min-width: 400px) {
    margin-left: 0;
  }
}

.desktopImg {
  display: none;

  > div {
    vertical-align: top;
  }
}

.contentWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 13;
  padding-top: 100px;
}

.header {
  max-width: 615px;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 40px;
}

.links {
  margin-top: 40px;
}

.link {
  color: white;
  text-decoration: underline;
  margin: 0 15px;
}

.decoration {
  width: 70px;
  position: absolute;
  bottom: -45px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@include media-tablet {
  .imgWrap {
    overflow: hidden;
    min-height: 40rem;
  }

  .mobileImg {
    display: none;
  }

  .desktopImg {
    display: block;
    width: 195vh;
    margin-left: -20%;
  }

  .contentWrap {
    padding-top: 80px;
  }

  .decoration {
    margin: initial;
    right: initial;
    left: 10%;
  }
}

@include media-desktop {
  .imgWrap {
    height: 65vh;
    min-height: 40rem;
  }
  .desktopImg {
    margin-left: -15%;
  }

  .contentWrap {
    padding-top: 5rem;
  }

  .title {
    font-size: 56px;
    line-height: 56px;
  }

  .subtitle {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
    font-weight: 500;
  }

  .decoration {
    margin: initial;
    right: initial;
    left: 10%;
  }
}

@media only screen and (min-width: 1300px) {

  .desktopImg {
    width: 110vw;
    margin-left: -4.5rem;
    min-height: 480px;
  }

  .contentWrap {
    padding-top: 0;
    display: flex;
    align-items: center;
  }

  .imgWrap {
    height: 65vh;
    min-height: 33rem;
  }
}
