
        @import 'styles/variables';
        @import 'styles/media';
      
.wrapper {
    width: 100%;
    height: 101vh;
    position: fixed;
    // Necessary for iPhone
    margin-top: -2px
}

.inputStyle {
    padding: 30px 60px 30px 30px!important;
    border: none !important;
    border-radius: 0!important;
    margin-bottom: 0!important;
    background-color: #fff;
}
 
.searchWrap {
    background-color: #fff;
    padding: 8px 25px;
}

.otherWrap {
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.6;
}

.inputArea {
    border-bottom: solid 1px #dedede;
}

.event {
    padding: 8px 0;
}

.eventLink {
    text-decoration: none;
}

.clearIcon {
    position: absolute;
    height: 1rem;
    opacity: 0.3;
    content: url('/img/search-clear.svg');
    z-index: 10000;
    top: 37px;
    right: 30px;
  }

  .row {
    display: flex; 
    flex-direction: row;
    align-items: center;
    padding: 15px;
    width: 100%;
    justify-content:center
  }
  
  .colSpinner {
    display: flex;
    padding: 0 10px 0 0
  }
  
  .colText {
    display: flex;
    padding: 0 5px
  }
  
  .spinner {
    width: 50px
  }
  
  .loadingText {
    font-weight: 600;
  }