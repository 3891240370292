
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
}

.imgWrap {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #000000 100%)
      0% 0% no-repeat padding-box;
    opacity: 0.75;
    z-index: 10;
  }
}

.contentWrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 0.5rem 0.3rem;
  z-index: 11;
}

.title {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 0;
}

.mobileImg,
.desktopImg {
  > div {
    height: 8.7rem;
  }

  img {
    object-fit: contain;
    object-position: center center;
  }
}

.desktopImg {
  display: none;
}

@media only screen and (min-width: 768px) {
  .mobileImg,
  .desktopImg {
    > div {
      height: 19.2rem;
    }
  }
  .title {
    font-size: 32px;
  }
  .contentWrap {
    padding: 0.75rem 1rem;
  }
  .mobileImg {
    display: none;
  }

  .desktopImg {
    display: block;
  }

  .text {
    font-size: 16px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .mobileImg,
  .desktopImg {
    > div {
      height: 24.35rem;
    }
  }
  .contentWrap {
    padding: 1.25rem 2.25rem;
  }
}
