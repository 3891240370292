
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-top: -80px;
  margin-bottom: 80px;
  padding: 40px 0;
  background: $gray-050;
}

.header {
  margin-bottom: 24px;
  color: $color-pink;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}

.title {
  margin-bottom: 28px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.text {
  max-width: 770px;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0 auto;
}

.columnsWrap {
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 40px;
}

.column {
  padding: 0 15px;
  margin: 0 0 24px;
  width: 100%;
}

.columnTitle {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  margin-bottom: 5px;
}

.columnText {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .section {
    padding: 90px 0;
  }

  .text {
    margin: 0 auto
  }

  .columnsWrap {
    margin-top: 82px;
    flex-direction: row;
  }

  .column {
    margin: 0;
    width: 33.333%;
  }

  .columnTitle {
    font-size: 24px;
    line-height: 36px;
  }

  .columnText {
    max-width: 350px;
  }
}
