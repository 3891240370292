
        @import 'styles/variables';
        @import 'styles/media';
      
.link {
  text-decoration: none;
  color: white;
  font-size: 12px;
  line-height: 40px;

  &:hover {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 992px) {
  .title {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .link {
    line-height: 27px;
    font-size: 14px;
  }
}