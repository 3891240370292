
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-blush;
  padding: 50px 20px;
  z-index: 998
}

.top {
  padding-bottom: 40px;
  margin-top: 30px
}

.link {
  display: inline-block;
  color: $color-body;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 28px;

  &:hover {
    text-decoration: underline;;
  }
}

.mobileWrapper {
  padding-left: 20px;
}