
        @import 'styles/variables';
        @import 'styles/media';
      
.list {
  margin: 30px 0;
}

.link {
  display: inline-block;
  margin-right: 27px;
  color: white;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;;
  }
}

@media only screen and (min-width: 768px) {
  .list {
    margin: 0;
  }
}