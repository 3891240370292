
        @import 'styles/variables';
        @import 'styles/media';
      
.teaser {
  margin-bottom: 30px;
}

.wrap {
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.contentWrap {
  display: flex;
  justify-content: space-between;
  padding: 24px 18px;
}

.title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 0;
}