
        @import 'styles/variables';
        @import 'styles/media';
      
.searchField {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  width: 1.2rem;
  background-color: white;
  border: 1px solid rgba(60, 60, 60, 0.2) !important;
  border-radius: 2rem;
  &[typing='true'] {
    width: auto;
    position: absolute;
    @include media-desktop {
      position: static;
      width: 15rem;
    }
  }

  @include media-desktop {
    width: 2.9rem;
    height: 2.9rem;
  }
}
.searchIcon {
  position: relative;
  height: 1rem;
  content: url('/img/search-icon.svg');
}
.clearIcon {
  position: relative;
  height: 1rem;
  opacity: 0.3;
  content: url('/img/search-clear.svg');
}

.eventLink {
  text-decoration: none;
  color: black;
}

.inputStyle {
  margin: 0 0.5rem !important;
  padding: 0 !important;
  border: none !important;
}

.searchWrap {
  background-color: #fff;
  text-align: left;
  position: absolute;
  border-radius: 0 0 5px 5px;
  margin-top: 15px
}

.searchWrapFront {
  background-color: #fff;
  text-align: left;
  position: absolute;
  border-radius: 5px!important;
  margin-top: 5px
}

.event {
  padding: 8px 25px;
}

.noResultsText {
  font-weight: 600;
  margin: 15px 0;
  padding: 0 30px
}

.eventWrapper:hover {
  background-color: rgb(239, 239, 239);
}

.row {
  display: flex; 
  flex-direction: row;
  align-items: center;
  padding: 15px;
  width: 15rem;
  justify-content:center
}

.colSpinner {
  display: flex;
  padding: 0 10px 0 0
}

.colText {
  display: flex;
  padding: 0 5px
}

.spinner {
  width: 50px
}

.loadingText {
  font-weight: 600;
}