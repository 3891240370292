
        @import 'styles/variables';
        @import 'styles/media';
      
.mobileHeader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 666;
  max-width: 100vw;
  overflow-x: hidden;

  &:not(.alternative) {
    a {
      color: $color-body;
    }
  }
}

.menuOpen {
  height: 100%;
}
.alternative {
  background-color: transparent;
}

.wrap {
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
}

.logoWrap {
  flex-basis: 60%;
  display: flex;
  flex-direction: row;
}

.iconsWrap {
  display: flex;
  flex-basis: 40%;
  justify-content: flex-end;
}

.cartWrap {
  justify-content: flex-end;
}

.logo {
  height: 29px;
  width: auto;
}

@media only screen and (min-width: 992px) {
  .mobileHeader {
    display: none;
  }
}

.hidden {
  display: none
}
