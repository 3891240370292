
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 60px;
  overflow-x: hidden;
}

.header {
  position: relative;
}

.title {
  font-weight: 500;
  margin-bottom: 40px;
}

.description {
  margin-bottom: 40px;
  margin-top: -20px;
}

@media only screen and (min-width: 768px) {
  .section {
    margin-bottom: 60px;
  }
}
