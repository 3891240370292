
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 40px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}

.contentWrap {
  order: 2;
  flex-basis: 100%;
}

.imageWrap {
  order: 1;
  flex-basis: 100%;
}

.imageFrame {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  margin-bottom: 20px;
}

.text {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) {
  .wrap {
    flex-wrap: nowrap;
  }

  .contentWrap {
    flex-basis: 50%;

    &:not(.reverse) {
      order: 1;
      padding-right: 50px;
    }

    &.reverse {
      padding-left: 50px;
    }
  }

  .imageWrap {
    flex-basis: 50%;

    &:not(.reverse) {
      order: 2;
    }
  }
}

@media only screen and (min-width: 992px) {
  .contentWrap,
  .imageWrap {
    flex-basis: 50%;
  }

  .title {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
    font-weight: $h4-font-weight;
  }
}

@media only screen and (min-width: 1200px) {
  .contentWrap {
    &:not(.reverse) {
      padding: 40px 80px 40px 0;
    }

    &.reverse {
      padding: 40px 0 40px 70px;
    }
  }
}
