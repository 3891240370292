
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 80px;
}

.content {
  p {
    margin-bottom: 1.33rem;
    font-size: 16px;
    line-height: 1.5;
  }

  strong {
    font-weight: 700;
  }

  ol {
    padding-left: 20px;
    margin-bottom: 30px;

    li {
      margin-bottom: 5px;
    }
  }

  ol {
    list-style: decimal;
  }
}
