
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  color: $color-body;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Styleset for archive view.
 */ 
.alternative {
  .filter {
    select,
    input {
      color: $color-pink;
    }

    select,
    input {
      text-decoration: underline;;
    }

    &:first-child {
      border-top: 1px solid rgba(60, 60, 60, 0.2);
      border-left: 1px solid rgba(60, 60, 60, 0.2);
      border-right: 1px solid rgba(60, 60, 60, 0.2);
    }

    &:nth-child(2),
    &:nth-child(3) {
      border-left: 1px solid rgba(60, 60, 60, 0.2);
      border-right: 1px solid rgba(60, 60, 60, 0.2);
    }

    &:nth-child(4) {
      border-left: 1px solid rgba(60, 60, 60, 0.2);
      border-right: 1px solid rgba(60, 60, 60, 0.2);
      border-bottom: 1px solid rgba(60, 60, 60, 0.2);
    }
  }
}

.filter {
  flex-basis: 100%;
  border-bottom: 1px solid #E5E5E5;
  padding: 13px 23px 13px 40px;
  background-color: white;

  &:first-child {
    border-radius: 18px 18px 0 0;
    padding-top: 20px;
  }

  &:nth-child(3) {
    border-radius: 0 0 18px 18px;
    padding-bottom: 20px;
  }
}

.label {
  font-weight: 700;
  display: block;
  line-height: 0px;
}

.btnWrap {
  flex-basis: 100%;
  margin-top: 20px;
}

.submit {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .filter {
    flex-basis: 100%;

    &:nth-child(1) {
      border-radius: 18px 18px 0 0;
    }

    &:nth-child(2) {
      border-radius: 0;
      padding-top: 20px;
    }

    &:nth-child(3) {
      border-radius: 0 0 18px 18px;
    }
  }

  .alternative {
    .filter {
      &:first-child {
        border: none;
        border: 1px solid rgba(60, 60, 60, 0.2);
      }
  
      &:nth-child(2) {
        border: none;
        border-right: 1px solid rgba(60, 60, 60, 0.2);
        border-left: 1px solid rgba(60, 60, 60, 0.2);
      }

      &:nth-child(3) {
        border: none;
        border: 1px solid rgba(60, 60, 60, 0.2);
      }
    }
  }

  .btnWrap {
    text-align: start;
  }

  .submit {
    width: initial;
  }
}

@media only screen and (min-width: 1300px) {
  .section {
    border-radius: 44px;
    padding: 18px 20px 18px 40px;
    background-color: white;
    max-width: 100%;
  }

  .filter {
    background-color: transparent;
    flex: 1;
    border: none;
    border-right: 1px solid #E5E5E5;
    padding: 0 50px 0 40px !important;
    border-radius: 0 0 0 0 !important;

    &:nth-child(2) {
      flex: 1.5;
    }
  
    &:nth-child(3) {
      flex: 1.5;
    }
  
    &:last-of-type {
      border-right: initial;
    }
  }

  .alternative {
    border: 1px solid rgba(60, 60, 60, 0.2);

    .filter {
      border: none !important;
      border-right: 1px solid #E5E5E5 !important;

      &:last-of-type {
        border-right: initial !important;
      }
    }
  }

  .btnWrap {
    flex-basis: initial;
    margin: 0;
  }

  .label {
    color: initial;
    line-height: initial;
  }
}