
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 50px;
  overflow-x: hidden;
}

.header {
  position: relative;
  margin-bottom: 40px;
}

.title {
  font-weight: 500;
  margin-bottom: 10px;
}

.description {
  max-width: 450px;
}

.headerBtn {
  display: none;
}

.btnWrap {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -15px;
    margin-right: -15px;

    article {
      flex-basis: 50%;

      > div {
        margin: 15px;
      }
    }
  }

  .headerBtn {
    display: inline-block;
    position: absolute;
    top: 26px;
    right: 0;
  }

  .btnWrap {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .section {
    margin-bottom: 50px;
  }

  .title {
    font-size: $h4-font-size;
    line-height: 1.25;
  }

  .wrap {

    article {
      flex-basis: 33%;
    }
  }
}
