
        @import 'styles/variables';
        @import 'styles/media';
      

.sliderRow {
  position: relative;
  width: 100%;
}

.sliderContainer {
  max-width: 100%;
  //overflow: hidden;
}

.next,
.prev {
  position: absolute;
  visibility: hidden;
}

.controlContainer {
  position: relative;
}

.controlsLeft {
  top: 0px;
  left: 0px;
}

.controlsRight {
  top: 0px;
  right: 0px;
}

@media only screen and (min-width: 1200px) {
  .prev,
  .next {
    visibility: visible;
    bottom: 305px;

  }

  .next {
    cursor: pointer;
    right: -20px;
  }

  .prev {
    transform: scaleX(-1);
    cursor: pointer;
    left: -50px;
  }
}
