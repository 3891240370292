
        @import 'styles/variables';
        @import 'styles/media';
      
.images,
.image {
  position: relative;
  max-height: 222px;
  overflow: hidden;

  @include media-tablet {
    max-height: 209px;    
  }

  @include media-desktop {
    max-height: 238px;    
  }
}

.controlsLeft,
.controlsRight {
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 15;
  cursor: pointer;
}

.controlsRight {
  left: initial;
  right: 0;
}

.prev,
.next {
  cursor: pointer;
  margin: 10px;
}

.prev {
  right: initial;
  left: 0;
  transform: scaleX(-1);
  margin-right: 20px;
}