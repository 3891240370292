
        @import 'styles/variables';
        @import 'styles/media';
      
.link {
  color: $color-pink;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  margin-right: 15px;

  &:hover {
    text-decoration: underline;
  }
}

.altLink {
  color: white;
}

.icon {
  margin-top: 3px;
  margin-right: 7px
}

.iconNoMargin {
  margin-top: 3px;
}
.amount {
  margin-top: 1px;
  color: $color-pink
}

.amountWhite {
  margin-top: 1px;
  color: #fff
}

@media only screen and (min-width: 992px) {
  .amount {
    margin-top: 3px
  }

  .amountWhite {
    margin-top: 3px
  }

  .link {
    margin-right: 0
  }
}
