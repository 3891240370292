
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 80px;
  background-color: $color-blush;
}

.wrap {
  position: relative;
  padding: 30px 20px 30px 20px;
}

.button {
  min-width: auto;
  max-width: auto;
}

.imageSuccess {
  transform: rotateY(180deg);
  text-align: center;
}

.titleSuccess {
  text-align: center;
}

.input {
  border-radius: 25px!important;
  padding: 15px 30px!important;
  border-style: none!important;
  color: $color-pink
}

.input::placeholder {
  color: $color-pink!important
}

.decoration {
  display: none;
}

.content {
  text-align: center
}

@media only screen and (min-width: 768px) {
  .wrap {
    padding: 70px 80px 60px;
  }
  .button {
    min-width: 200px;
    max-width: 220px;
  }
}

@media only screen and (min-width: 992px) {

  .decoration {
    display: block;
    position: absolute;
    width: 75px;
    top: -27px;
    right: 20px;
    left: auto;
    transform:rotateY(0deg)
  }

  .content {
    text-align: left
  }
}

