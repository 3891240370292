
        @import 'styles/variables';
        @import 'styles/media';
      
.title {
  margin-bottom: 33px;
}

@media only screen and (min-width: 992px) {
  .title {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}