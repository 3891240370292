
        @import 'styles/variables';
        @import 'styles/media';
      
.section {
  margin-bottom: 80px;
  padding: 40px 0;
  background: $gray-050;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.description {
  text-align: center;
}


.columnsWrap {
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
}

.column {
  padding: 0 15px;
  margin: 0 0 24px;
  width: 100%;
}

.box {
  background: #fff;
  box-shadow: 0px 4px 4px $gray-050;
  border-radius: 10px;
  height: 100%;
  padding: 38px 24px;
  border: 5px solid #fff;
  text-align: center;
}

.boxPopular {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  border: solid 5px transparent;


  &::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -5px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: -webkit-linear-gradient(left, $color-pink, $color-orange);
    background: linear-gradient(90deg, $color-pink, $color-orange);
  }
}

.planPopular {
  position: absolute;
  text-transform: uppercase;
  padding: 8px 16px;
  background: linear-gradient(90deg, $color-pink, $color-orange);
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 134px;
}

.planHeader {
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  color: rgba(60, 60, 60, 0.4);
  font-weight: 500;
}

.planTitle {
  margin-bottom: 30px;
  margin-top: 10px;
}

.planPrice {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: $color-turquoise;
  margin-bottom:4px;
}

.features {
  margin-top: 40px;
}

.feature {
  position: relative;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  margin-top: 8px;
  padding-left: 32px;

  &::before {
    position: absolute;
    left: 0;
    top: 6px;
    content: "";
    width: 14px;
    height: 8px;
    border-left: 2px solid $color-pink;
    border-bottom: 2px solid $color-pink;
    transform: rotate(-45deg);
  }
}

@media only screen and (min-width: 768px) {
  .section {
    padding: 80px 0;
  }

  .box {
    padding: 46px 32px;
  }

  .columnsWrap {
    margin-top: 56px;
    flex-direction: row;
  }

  .column {
    margin: 0;
    width: 33.333%;
  }

  .features {
    margin-top: 56px;
  }
}
