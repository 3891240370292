// *********
// Variables
// *********

// Colors
$color-pink:       #FF658B;
$color-orange:     #FF8700;
$color-blush:      #FFD1BD;
$color-turquoise:  #5CCAAE;
$color-dark-grey:  #3C3C3C;
$color-body:       #2E2E2E;
$color-link:       $color-pink;
$color-link-hover: #000;

// Opacity variations.
$color-blush-50:   rgba(255, 209, 189, 0.5);

// Grey
$gray-050: rgba(60, 60, 60, 0.05);
$gray-100: rgba(60, 60, 60, 0.1);
$gray-200: rgba(60, 60, 60, 0.2);
$gray-400: rgba(60, 60, 60, 0.4);
$gray-600: rgba(60, 60, 60, 0.6);
$gray-800: rgba(60, 60, 60, 0.8);

/* #################################### */

// Fonts
$font-body:           'Poppins', sans-serif;
$font-heading:        'Poppins', sans-serif;

// Weights.
$font-normal: 400;
$font-bold: 700;

/* #################################### */

// Body styles mobile
$body-font-size-mobile:        16px;
$body-line-height-mobile:      1.75;
$body-letter-spacing-mobile:   normal;
$body-font-weight-mobile:      $font-normal;

// H1 styles mobile
$h1-font-size-mobile:          2rem;
$h1-line-height-mobile:        1.25;
$h1-letter-spacing-mobile:     0;
$h1-font-weight-mobile:        $font-bold;

// H2 styles mobile
$h2-font-size-mobile:          1.5rem;
$h2-line-height-mobile:        1.5;
$h2-letter-spacing-mobile:     0;
$h2-font-weight-mobile:        $font-bold;

// H3 styles mobile
$h3-font-size-mobile:          1.125rem;
$h3-line-height-mobile:        1.5;
$h3-letter-spacing-mobile:     0;
$h3-font-weight-mobile:        $font-bold;

// H4 styles mobile
$h4-font-size-mobile:          1rem;
$h4-line-height-mobile:        1.5;
$h4-letter-spacing-mobile:     0;
$h4-font-weight-mobile:        bold;

// H5 styles mobile
$h5-font-size-mobile:          1rem;
$h5-line-height-mobile:        1.75;
$h5-letter-spacing-mobile:     0;
$h5-font-weight-mobile:        $font-bold;

// H6 styles mobile
$h6-font-size-mobile:          1rem;
$h6-line-height-mobile:        1.75;
$h6-letter-spacing-mobile:     0;
$h6-font-weight-mobile:        $font-bold;

// Small style.
$body-small-font-size-mobile:        0.875rem;
$body-small-line-height-mobile:      1.5;
$body-small-letter-spacing-mobile:   normal;
$body-small-font-weight-mobile:      $font-normal;

// Extra small style. Does not vary by screen size.
$body-extra-small-font-size-mobile:        14px;
$body-extra-small-line-height-mobile:      21px;
$body-extra-small-letter-spacing-mobile:   normal;
$body-extra-small-font-weight-mobile:      $font-normal;


/* #################################### */

// Body styles desktop
$body-font-size:        18px;
$body-line-height:      1.5;
$body-letter-spacing:   normal;
$body-font-weight:      $font-normal;

// H1 styles desktop
$h1-font-size:          4.225rem;
$h1-line-height:        1;
$h1-letter-spacing:     0;
$h1-font-weight:        $font-bold;

// H2 styles desktop
$h2-font-size:          3.115rem;
$h2-line-height:        1;
$h2-letter-spacing:     0;
$h2-font-weight:        $font-bold;

// H3 styles desktop
$h3-font-size:          2.35rem;
$h3-line-height:        1;
$h3-letter-spacing:     0;
$h3-font-weight:        $font-bold;

// H4 styles desktop
$h4-font-size:          1.78rem;
$h4-line-height:        1.25;
$h4-letter-spacing:     0;
$h4-font-weight:        $font-bold;

// H5 styles desktop
$h5-font-size:          1.35rem;
$h5-line-height:        1.5;
$h5-letter-spacing:     0;
$h5-font-weight:        500;

// H6 styles desktop
$h6-font-size:          1rem;
$h6-line-height:        1.5;
$h6-letter-spacing:     0;
$h6-font-weight:        $font-bold;

// Small style.
$body-small-font-size:        0.89rem;
$body-small-line-height:      1.5;
$body-small-letter-spacing:   normal;
$body-small-font-weight:      $font-normal;
