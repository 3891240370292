
        @import 'styles/variables';
        @import 'styles/media';
      
.teaser {
  margin-bottom: 30px;
  min-height: 455px;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

.wrap {
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.1s ease-in-out;
  box-shadow: 0px 4px 50px 10px rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0px 4px 50px 10px rgba(0, 0, 0, 0.1);
  }
}

.contentWrap {
  padding: 20px 18px 20px;
}

.title {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.content {
  margin-bottom: 8px;
}

.price,
.guests {
  color: $color-pink;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.icon {
  margin-right: 7px;
  margin-top: -3px;
}

.linksWrap {
  padding: 0 18px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-basis: 100%;
  @media only screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    flex-direction: row;
  }
}

.link {
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  text-align: center;
  @media only screen and (min-width: 768px) {
    margin: 0 1rem;
  }

  &:hover {
    color: $color-pink;
  }
}

.cart {
  margin-left: 5px;
  @media only screen and (min-width: 768px) {
    margin-left: 10px;
  }
}

.button {
  font-weight: 400;

  &:hover {
    background-color: $color-turquoise;
  }
}
